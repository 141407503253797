import { useQuery } from '@tanstack/react-query';

import axios from 'axios';
import Hex from 'crypto-js/enc-hex';
import HmacSHA256 from 'crypto-js/hmac-sha256';

import extractData from 'utils/api';
import { withReturn } from 'zg_utils/tokens';

const zgClient = axios.create({
  baseURL: '/api/zygoat/',
  responseType: 'json',
  headers: {
    'X-REQUESTED-WITH': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

export const ZG_QUERY_STALE_TIME = 1000 * 60 * 10; // 10 minutes
export const zgDataQueryKey = ['zygoat-data'];
export const zgDataQueryFn = () => extractData(zgClient.get)('env/');

export const useZygoatEnvVariablesQuery = (overrides = {}) =>
  useQuery({
    queryKey: zgDataQueryKey,
    queryFn: zgDataQueryFn,
    staleTime: ZG_QUERY_STALE_TIME,
    // Allows any failed requests to trigger the error boundary
    useErrorBoundary: true,
    ...overrides,
  });

const useZygoatEnvVariables = () => {
  const { data, isLoading, error } = useZygoatEnvVariablesQuery();

  // Portunus URL constants
  const homeLink = typeof window === 'undefined' ? null : window.location.origin;
  const LOGOUT_URL = data?.portunusUrl ? `${data.portunusUrl}/api/logout` : null;
  const LOGIN_SETTINGS_URL = data?.portunusUrl
    ? withReturn(data.portunusUrl, true, homeLink)
    : null;

  // Tableu URL constants
  const TABLEAU_API = 'https://embedding.tableauusercontent.com/tableau.embedding.3.0.0.min.js';

  // Tipalti URL constants
  const TIPALTI_PAYER = 'MetLifeLegalPlans';

  const PAYEE_DASHBOARD_URL = 'payeedashboard/home';

  const PAYMENT_HISTORY_URL = 'PayeeDashboard/PaymentsHistory';

  const tipaltiEncryptQueryString = queryString => {
    // utf8 encode the string
    // encrypy with hmacsha256, use master key to encrypt
    // convert to hex
    const encodedString = data?.tipaltiMasterKey
      ? HmacSHA256(queryString, data.tipaltiMasterKey).toString(Hex)
      : null;
    return encodedString;
  };

  const makeURL = (payeeId, baseURL) => {
    // Add the unique payee id and also a timestamp (ts) so that tipalti can verify that the request
    // is current.
    const baseQuery = `idap=${payeeId}&ts=${Math.round(
      new Date().getTime() / 1000,
    )}&payer=${TIPALTI_PAYER}`;
    const signature = tipaltiEncryptQueryString(baseQuery);
    const baseUrl = data?.tipaltiUrl ? `${data.tipaltiUrl}${baseURL}?${baseQuery}` : null;
    return `${baseUrl}&hashkey=${signature}`;
  };

  const makeTipaltiPayeeInfoIframeURL = payeeId => makeURL(payeeId, PAYEE_DASHBOARD_URL);

  const makeTipaltiPaymentsIframeURL = payeeId => makeURL(payeeId, PAYMENT_HISTORY_URL);

  return {
    data,
    isLoading,
    error,
    LOGOUT_URL,
    LOGIN_SETTINGS_URL,
    TABLEAU_API,
    makeTipaltiPayeeInfoIframeURL,
    makeTipaltiPaymentsIframeURL,
  };
};

export default useZygoatEnvVariables;
