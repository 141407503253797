import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

export const addAttorney = payload => client.post('users/attorneys/', payload);

export const findAndUpdateAttorney = async payload => {
  const { data } = await client.patch(`users/attorneys/${payload.user.pk}/`, payload);
  return data;
};

export const validateAttorneyData = payload =>
  client.post('users/attorneys/validate-attorney/', payload);

export const updateAttorneyProfilePicture = async payload => {
  const { data } = await CLIENT_POST_FILE.post(
    `users/attorneys/${payload.user.pk}/update-attorney-photo/`,
    payload.formData,
  );
  return data;
};

export const getAttorneyProfilePicture = async pk => {
  const { data } = await CLIENT_GET_FILE.get(`users/attorneys/${pk}/attorney-photo/`);
  return data;
};

export const getAttorneyInfoOptions = async id => {
  const { data } = await client.post('users/attorneys/options/', { firm_id: id });
  return data;
};

export const changePrimaryAttorney = payload =>
  client.post('users/attorneys/change-primary-attorney/', payload);
