export const FEE_REIMBURSEMENT = 'Fee Reimbursement';
export const NEW_CLAIM = 'New Claims';
export const KICKED_CLAIM = 'Claims Under Review';
export const PENDING_CLAIM = 'Claims Pending Approval';

export const CLAIMS_INBOX = 'Claims Inbox';
export const FIRM_UPDATE = 'Firm Update';
export const COVERAGE_DETAILS = 'Coverage Details';
export const CLOSED_CLAIMS = 'Closed Claims';
export const OPEN_CLAIMS = 'Open Claims';
export const APPROVED_CLAIMS = 'Approved Claims';
export const CLAIM_HISTORY = 'Claim History';
export const ALL_CLAIMS_FOR_MEMBER = 'All Claims for Member';
export const RECENT_CLAIMS_TABLE = 'recent';
export const ALL_PAID_CLAIMS_TABLE = 'all';

export const MALPRACTICE_SUBMITTED_STATUS = 'Proof of Insurance Submitted';
export const MALPRACTICE_APPROVED_STATUS = 'Proof of Insurance Approved';

export const REASONS_FOR_ERROR_KICK = [
  'Kick should not have been triggered',
  'Kick triggered for an incorrect amount',
  'Kick should not be automatically overridden',
  'Other (please explain)',
];

export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const SIX_HOURS_IN_MILLISECONDS = DAY_IN_MILLISECONDS / 4;
