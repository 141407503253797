import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { Integrations } from '@sentry/tracing';

import useZygoatEnvVariables from 'hooks/query-hooks/useZygoatEnvVariables';

import tokenFetcher, { withReturn } from 'zg_utils/tokens';

const isSsr = typeof window === 'undefined';

const ZygoatEnvDataLoader = () => {
  const { data, isLoading } = useZygoatEnvVariables();

  useEffect(() => {
    if (!isSsr && !isLoading && data) {
      // Once the zygoat data is fetched, initialize Sentry and the token fetcher.
      if (data.sentryDsn) {
        // Initialize Sentry with the env data once we get it.
        if (data.sentryDsn) {
          Sentry.init({
            dsn: data.sentryDsn,
            environment: data.sentryEnvironment,
            integrations: [new Integrations.BrowserTracing()],
          });
        }
      }
      if (data.portunusUrl) {
        const onErrorHandler = () => {
          window.location.replace(withReturn(`${data.portunusUrl}/login`));
        };
        tokenFetcher.start(data.portunusUrl, null, null, onErrorHandler);
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    return () => tokenFetcher.clearToken();
  }, []);

  return null;
};

export default ZygoatEnvDataLoader;
